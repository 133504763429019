import { Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import services_home from '../images/Services/services_home.jpg';
import services_section_1 from '../images/Services/services_section_1.jpg';
import services_section_2 from '../images/Services/services_section_2.jpg';
import services_section_3 from '../images/Services/services_section_3.jpg';
import '../styles/services.css';
import Footer from './Footer';
import Navbar from './Navbar';

function Services() {
  const navigate = useNavigate();
  return (
    <>
      <div className="service-grid-container">
        <div className="service-header">
          <Navbar />
        </div>
        <div className="service-item-section">
          <Typography
            variant="h4"
            style={{
              width: '50%',
              wordBreak: 'break-word',
              textAlign: 'center'
            }}>
            Everything you need to manage your application on a single platform
          </Typography>
        </div>
        <div className="service-item2">
          <Card
            style={{ width: '345px', margin: '20px' }}
            onClick={() => {
              navigate('/services/sap');
            }}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="250"
                image={services_section_1}
                alt="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  SAP Consulting
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  We provide innovative and next gen strategic advice, application development and
                  SAP Consulting
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card
            style={{ maxWidth: 345, width: '345px', margin: '20px' }}
            onClick={() => {
              navigate('/services/managed');
            }}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="250"
                image={services_section_2}
                alt="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  Manged Services (AMS)
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Incorporate practices for process standardization, reduce redundancies and higher
                  productivity
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card
            style={{ maxWidth: 345, width: '345px', margin: '20px' }}
            onClick={() => {
              navigate('/services/integration');
            }}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="250"
                image={services_section_3}
                alt="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  Integration Services
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Automate business with confidence and agility using multiple applications and data
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
        <div className="service-item-section">
          <Typography
            variant="h4"
            style={{
              width: '50%',
              wordBreak: 'break-word',
              textAlign: 'center'
            }}>
            We at Trivine Infotech
          </Typography>
        </div>
        <div
          className="services-grid-container"
          style={{
            backgroundImage: `url(${services_home})`,
            height: 'auto',
            paddingTop: '100px',
            paddingBottom: '100px'
          }}>
          <div className="services-grid-item">
            <Typography variant="h5">We prioritize smart problem solving</Typography>
            <Typography variant="body2" className="services-grid-item-message">
              There can be multiple solutions to a problem. We not only make sure that the solution
              given streamlines the process but also focus on the fact that the solution given to
              the customer is perfect for him.
            </Typography>
          </div>
          <div className="services-grid-item">
            <Typography variant="h5">We build the nexus of talent and innovation</Typography>
            <Typography variant="body2" className="services-grid-item-message">
              There is always a constant push and encouragement for innovation and out-of-the-box
              thinking. Resulting in up-to-date and latest solution with tremendous individual
              growth.
            </Typography>
          </div>
          <div className="services-grid-item">
            <Typography variant="h5">We reach for a more inclusive future</Typography>
            <Typography variant="body2" className="services-grid-item-message">
              We are here for a reason. We believe in the ideology of the internet for all and are
              making sure we do not harm the earth in the process. Sustainable and safe growth is
              the key to the future.
            </Typography>
          </div>
        </div>
      </div>
      <div className="service-footer">
        <Footer />
      </div>
    </>
  );
}

export default Services;

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import HistoryIcon from '@material-ui/icons/History';
import MapIcon from '@material-ui/icons/Map';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import logo_1 from '../images/Logo/logo_1.png';
import logo_2 from '../images/Logo/logo_2.png';
import logo_3 from '../images/Logo/logo_3.png';
import logo_4 from '../images/Logo/logo_4.png';
import logo_5 from '../images/Logo/logo_5.png';
import work from '../images/desk.png';
import macbook from '../images/macbook.jpg';
import network1 from '../images/network3.gif';
import '../styles/home.css';
import '../styles/navbar.css';

function Home() {
  const navigate = useNavigate();
  const [counter1, setCounter1] = React.useState(0);
  const [counter2, setCounter2] = React.useState(0);
  const [counter3, setCounter3] = React.useState(0);
  const [counter4, setCounter4] = React.useState(0);
  const limiter1 = 7;
  const limiter2 = 10;
  const limiter3 = 15;
  const limiter4 = 3;
  React.useEffect(() => {
    const incrementer1 = setInterval(() => {
      setCounter1((c) => {
        if (c < limiter1) return c + 1;
        clearInterval(incrementer1);
        return c;
      });
    }, 900);
    const incrementer2 = setInterval(() => {
      setCounter2((c) => {
        if (c < limiter2) return c + 1;
        clearInterval(incrementer2);
        return c;
      });
    }, 700);
    const incrementer3 = setInterval(() => {
      setCounter3((c) => {
        if (c < limiter3) return c + 1;
        clearInterval(incrementer3);
        return c;
      });
    }, 500);
    const incrementer4 = setInterval(() => {
      setCounter4((c) => {
        if (c < limiter4) return c + 1;
        clearInterval(incrementer4);
        return c;
      });
    }, 1000);
  }, []);

  return (
    <div className="home-container">
      <div className="home-header">
        <Navbar />
      </div>
      <div className="home-header-image">
        <img src={macbook} className="App-logo" alt="logo" />
      </div>
      <div className="home-title">
        <div className="intro">
          <Typography variant="h5" style={{ transform: 'translateY(-50);', transition: '0.5s' }}>
            Trivine Infotech at your service
          </Typography>
        </div>
        <div className="intro-message">
          <Typography variant="body1">Simplifying Complexity through Technology</Typography>
        </div>
      </div>
      <div className="vl"></div>
      <div className="home-read">
        <div className="side">
          <img
            alt="facebook"
            width="100%"
            src={network1}
            style={{ width: '100%', height: '350px' }}
          />
        </div>
        <div className="side side-content-center">
          <div>
            <Typography variant="h5">
              Start your journey towards Digital Transformation today!
            </Typography>
            <Typography
              paragraph
              style={{
                paddingTop: '30px'
              }}>
              Work with the largest network of independent professionals and get things done—from
              quick turnarounds to big transformations. We specialize in SAP new implementations,
              Applications Maintenance & Support.
            </Typography>
            <button
              className="readMoreBtn"
              onClick={() => {
                navigate('/services');
              }}>
              READ MORE
            </button>
          </div>
        </div>
      </div>
      <div className="home-trivine margin36">
        <Typography variant="h4" className="font40">
          Why Trivine?
        </Typography>
        <Typography paragraph className="home-trivine-text">
          A full service provider of SAP solutions, we offer our customers a broad range of SAP
          consulting and support services. Trivine provides technology solutions for all domains
          that address the complex business needs of its clients. With a unique combination of
          domain knowledge and technology expertise, Trivine helps meet the strategic goals of
          companies.
        </Typography>
      </div>
      <div className="home-trivine">
        <Typography
          variant="h4"
          style={{
            fontSize: '40px',
            lineHeight: '50px',
            background: 'linear-gradient(90deg,#f32170,#ff6b08,#cf23cf,#ed4)',
            '-webkit-text-fill-color': 'transparent',
            '-webkit-background-clip': 'text'
          }}>
          We partner with you through each step of the process
        </Typography>
        <div className="home-benefits">
          <Card className="card-section">
            <CardActionArea>
              <CardContent>
                <ThumbUpIcon className="orangeColor" />
                <Typography gutterBottom variant="h6" component="div" className="orangeColor">
                  Reliable
                </Typography>
                <Typography variant="body2" color="text.secondary" className="padding12">
                  Our team of experts work with you to ensure that you are provided services
                  correctly and on time.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card className="card-section">
            <CardActionArea>
              <CardContent>
                <CompareArrowsIcon className="orangeColor" />
                <Typography gutterBottom variant="h6" component="div" className="orangeColor">
                  Simple
                </Typography>
                <Typography variant="body2" color="text.secondary" className="padding12">
                  A full service provider of SAP solutions, we offer our customers a broad range of
                  SAP consulting and support services. For any help, your Customer Success Expert is
                  a call away.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card className="card-section">
            <CardActionArea>
              <CardContent>
                <MapIcon className="orangeColor" />
                <Typography gutterBottom variant="h6" component="div" className="orangeColor">
                  Transparent
                </Typography>
                <Typography variant="body2" color="text.secondary" className="padding12">
                  The personal relationship we build with each customer is essential, as it ensures
                  the account is managed with efficiency, continuity and consistently.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card className="card-section">
            <CardActionArea>
              <CardContent>
                <HistoryIcon className="orangeColor" />
                <Typography gutterBottom variant="h6" component="div" className="orangeColor">
                  Quick Workaround
                </Typography>
                <Typography variant="body2" color="text.secondary" className="padding12">
                  The personal relationship we build with each customer is essential, as it ensures
                  the account is managed with efficiency, continuity and consistently.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      </div>
      <div className="grid-container">
        <div className="grid-item home-experience-desc">
          <Typography variant="h5">
            Implement sophisticated process and technology solutions.
          </Typography>
          <Typography className="padding24">
            Our SAP consulting teams stand ready to support your digital transformation.
          </Typography>
          <button
            className="readMoreBtn"
            onClick={() => {
              navigate('/services');
            }}>
            SEE MORE
          </button>
        </div>

        <div className="grid-item home-experience">
          <div className="grid-item1">
            <Typography className="white count">{counter1}+</Typography>
            <Typography className="white text">Years' Experience</Typography>
          </div>
          <div className="grid-item1">
            <Typography className="white count">{counter2}+</Typography>
            <Typography className="white text">Happy Customers</Typography>
          </div>
          <div className="grid-item1">
            <Typography className="white count">{counter3}</Typography>
            <Typography className="white text">Projects</Typography>
          </div>
          <div className="grid-item1">
            <Typography className="white count">{counter4}K+</Typography>
            <Typography className="white text">Project Hours</Typography>
          </div>
        </div>
      </div>
      <div className="home-testimonial">
        <Typography className="home-testimonial-text" variant="h4">
          Trusted by Companies and Entrepreneurs Throughout the Country
        </Typography>
      </div>
      <div className="home-companies">
        <img src={logo_1} className="home-company" alt="logo1" />
        <img src={logo_2} className="home-company" alt="logo2" />
        <img src={logo_3} className="home-company" alt="logo3" />
        <img src={logo_4} className="home-company" alt="logo4" />
        <img src={logo_5} className="home-company" alt="logo5" />
      </div>
      <div className="home-contact">
        <img className="home-contact-work" src={work} alt="home" />
        <Typography variant="h5" className="home-contact-work-text">
          Let's Take Your Business to the Next Level
        </Typography>
        <button
          className="readMoreBtn"
          onClick={() => {
            navigate('/trivine');
          }}>
          CONTACT US
        </button>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
}

export default Home;

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import CallIcon from '@material-ui/icons/Call';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MailIcon from '@material-ui/icons/Mail';
import clsx from 'clsx';
import * as React from 'react';
import trivine_1 from '../images/Trivine/trivine_1.jpg';
import trivine_2 from '../images/Trivine/trivine_2.jpg';
import '../styles/trivine.css';
import Footer from './Footer';
import Navbar from './Navbar';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '75%',
    marginTop: '75px',
    marginBottom: '24px',
    backgroundColor: 'rgb(234, 111, 102)',
    color: 'white',
    borderRadius: '15px'
  },
  media: {
    height: 0,
    paddingTop: '56.25%' // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  avatar: {
    backgroundColor: red[500]
  }
}));

function Trivine() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="about-grid-container">
      <div className="about-header">
        <Navbar />
      </div>
      <div className="about-item2">
        <Card className={classes.root}>
          <CardHeader title="Trivine Infotech" className="about-info-header" />
          <CardMedia className={classes.media} image={trivine_2} title="About Trivine" />
          <CardContent>
            <Typography paragraph>
              At Trivine Infotech, we follow a client-centric approach, focusing on understanding
              your unique business requirements and aligning our solutions with your strategic
              goals. We believe in forging strong partnerships with our clients, collaborating
              closely at every stage of the engagement to ensure successful outcomes.
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            <Typography className="bold">KNOW MORE</Typography>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more">
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography paragraph className="bold">
                Why Choose Trivine Infotech?
              </Typography>
              <Typography paragraph>When you partner with us, you benefit from:</Typography>
              <Typography paragraph className="bold">
                Deep SAP Expertise:
              </Typography>
              <Typography paragraph>
                Our team of SAP-certified professionals brings extensive knowledge and expertise to
                deliver high-quality solutions.
              </Typography>
              <Typography paragraph className="bold">
                Tailored Solutions:
              </Typography>
              <Typography paragraph>
                We understand that each business is unique, and we customize our services to meet
                your specific requirements, ensuring a perfect fit for your organization.
              </Typography>
              <Typography paragraph className="bold">
                Proven Methodologies:
              </Typography>
              <Typography paragraph>
                We follow industry best practices and proven methodologies to deliver projects on
                time and within budget, ensuring quality and efficiency.
              </Typography>
              <Typography paragraph className="bold">
                Client Satisfaction:
              </Typography>
              <Typography paragraph>
                Our commitment to customer satisfaction is unwavering. We go the extra mile to
                exceed expectations, delivering exceptional service and support at all times.
              </Typography>
              <Typography paragraph className="bold">
                Long-Term Partnership:
              </Typography>
              <Typography paragraph>
                We aim to build long-lasting relationships with our clients, serving as their
                trusted SAP partner as they grow and evolve, providing ongoing support and guidance.
              </Typography>
            </CardContent>
          </Collapse>
        </Card>
      </div>
      <div className="about-item1">
        <img src={trivine_1} className="about-app-logo" alt="logo" />
      </div>
      <div className="about-item3">
        <div className="about-item">
          <Typography>
            <CallIcon className="location-icon" />
            Phone Number
          </Typography>
          <Typography paragraph>(022)-28060092</Typography>
          <Typography>
            <MailIcon className="location-icon" />
            Email Address
          </Typography>
          <Typography paragraph>info@trivineinfotech.com</Typography>
          <Typography>
            <LocationOnIcon className="location-icon" />
            Our Location
          </Typography>
          <Typography paragraph>
            B-301, Harmony Apt., Ashok Nagar, Kandivali East, Mumbai - 400101
          </Typography>
          <iframe
            width="100%"
            height="500px"
            title="Our Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15072.023976467965!2d72.8386425124166!3d19.19494054249442!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b71ed41e7bff%3A0x886bb86bab3cd5d4!2sHarmony%20complex!5e0!3m2!1sen!2sin!4v1687515994024!5m2!1sen!2sin"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
      <div className="about-footer">
        <Footer />
      </div>
    </div>
  );
}

export default Trivine;

import { Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import * as React from 'react';
import managed_1 from '../images/Managed/managed_1.jpg';
import managed_2 from '../images/Managed/managed_2.jpg';
import managed_3 from '../images/Managed/managed_3.jpg';
import managed_4 from '../images/Managed/managed_4.jpg';
import managed_5 from '../images/Managed/managed_5.jpg';
import '../styles/services.css';
import Footer from './Footer';
import Navbar from './Navbar';

function Services_Managed() {
  return (
    <>
      <div className="service-grid-container">
        <div className="service-header">
          <Navbar />
        </div>
        <div className="service-item1">
          <div style={{}}>
            <img
              src={managed_1}
              alt="Managed Services"
              style={{
                height: '350px',
                width: '100%',
                borderRadius: '5px'
              }}
            />
          </div>
          <div style={{ flex: 1, textAlign: 'center', paddingTop: '24px' }}>
            <Typography
              variant="h5"
              style={{
                background: 'linear-gradient(90deg,#f32170,#ff6b08,#cf23cf,#ed4)',
                '-webkit-text-fill-color': 'transparent',
                '-webkit-background-clip': 'text'
              }}>
              Managed Services
            </Typography>
            <Typography style={{ padding: '24px', textAlign: 'left' }}>
              Trivine Infotech offers Offshore/Onsite support services involve a wide and extensive
              range of technology drive attributes that includes Application Development, Business
              Process improvements, Business processing, Hosting, Technology infrastructure and
              development. While technology is at the core, we incorporate the next-and-best
              practices to drive process standardization, reduce system redundancies, and higher
              productivity levels.
            </Typography>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', margin: '36px' }}>
          <Typography
            variant="h4"
            style={{
              width: '50%',
              wordBreak: 'break-word',
              textAlign: 'center',
              background: 'linear-gradient(90deg,#f32170,#ff6b08,#cf23cf,#ed4)',
              '-webkit-text-fill-color': 'transparent',
              '-webkit-background-clip': 'text'
            }}>
            Your one-stop for SAP application management services
          </Typography>
        </div>
        <div className="service-item2">
          <Card style={{ width: '345px', margin: '20px' }}>
            <CardActionArea>
              <CardMedia component="img" height="250" image={managed_3} alt="green iguana" />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  24×7 Delivery and Agile Model
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Designed carefully by our industry experts we ensure that all your queries are
                  looked after 24x7 helping you carry out your business with ease.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card style={{ maxWidth: 345, width: '345px', margin: '20px' }}>
            <CardActionArea>
              <CardMedia component="img" height="250" image={managed_4} alt="green iguana" />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  Customer Focused Service Specialists
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  One of our core principle is being customer centric. Whatever we do has customer
                  at its heart. Experience the redefining customer experience with Trivine Infotech
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card style={{ maxWidth: 345, width: '345px', margin: '20px' }}>
            <CardActionArea>
              <CardMedia component="img" height="250" image={managed_5} alt="green iguana" />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  Dedicated Account Managers
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  The personal relationship we build with each customer is essential, as it ensures
                  the account is managed with efficiency, continuity and consistently.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
        <div className="service-item3">
          <div style={{ flex: 1, textAlign: 'center', paddingTop: '24px' }}>
            <Typography
              variant="h5"
              style={{
                background: 'linear-gradient(90deg,#f32170,#ff6b08,#cf23cf,#ed4)',
                '-webkit-text-fill-color': 'transparent',
                '-webkit-background-clip': 'text'
              }}>
              Get the most out of your ERP
            </Typography>
            <Typography style={{ padding: '24px', textAlign: 'left' }}>
              Many companies currently invest millions to establish evermore complex enterprise
              software solutions, but often leave the real value of these solutions untapped. They
              succumb to the common misconception that creating the system is an end in itself.
            </Typography>
            <Typography style={{ padding: '24px', textAlign: 'left' }}>
              Trivine's AMS for SAP specialists provides a flexible structure that enables
              businesses to fine-tune application management services (AMS) to their IT and business
              objectives. Trivine's regional and global delivery network offers access to the right
              resources, the right skill sets, and the right experience to solve our clients’ most
              important application management objectives, anywhere in the world. We can help
              clients to overcome challenges to improve application performance and achieve better
              business results.
            </Typography>
          </div>
          <div>
            <img
              src={managed_2}
              alt="Managed Services"
              style={{
                height: '350px',
                width: '100%',
                borderRadius: '5px'
              }}
            />
          </div>
        </div>
        <div className="service-item4">
          <div>
            <img
              src={managed_1}
              alt="Let the method be your guide"
              style={{
                height: '200px',
                width: '100%',
                borderRadius: '5px'
              }}
            />
          </div>
          <div style={{ flex: 1, textAlign: 'center', paddingTop: '24px' }}>
            <Typography variant="h5">Let the method be your guide</Typography>
            <Typography style={{ padding: '24px', textAlign: 'left' }}>
              We offer a proven, cost-effective way for companies to rapidly stabilize their
              enterprise solutions and transition into application optimization and innovation.
            </Typography>
          </div>
        </div>
      </div>
      <div className="service-footer">
        <Footer />
      </div>
    </>
  );
}

export default Services_Managed;

import { Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import CommentIcon from '@material-ui/icons/Comment';
import * as React from 'react';
import '../styles/about.css';
import Footer from './Footer';
import Navbar from './Navbar';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '-50px',
    width: '70%',
    display: 'flex',
    backgroundColor: 'rgb(234, 111, 102)',
    color: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100px'
  },
  info: {
    marginTop: '36px',
    padding: '24px',
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

function About() {
  const classes = useStyles();
  return (
    <div className="trivine-grid-container">
      <div className="trivine-header">
        <Navbar />
      </div>
      <div className="trivine-item2">
        <div className="trivine-item"></div>
      </div>
      <div className="trivine-item3">
        <Card className={classes.root}>
          <Typography variant="h5">About</Typography>
        </Card>
      </div>
      <div className="trivine-item4">
        <Card className={classes.info}>
          <Typography variant="h5">
            Skilled Administrative Support Professional with 7+ Years' Experience
          </Typography>

          <Typography paragraph style={{ textAlign: 'center', marginTop: '24px' }}>
            Trivine Infotech started in 2017 as a team tech entusiast with a desire to do something
            new. Together, we wanted to help global enterprises with software and reinvent how
            business is done. Managed by a professional team of young entrepreneurs who brings
            International experience in technical, functional, business consulting and management to
            the organization. Talented resource pool of experienced certified consultants, solutions
            architects and project managers. Knowledge and expertise of our resources is backed by
            proven end-to-end project execution methodology
          </Typography>
          <Typography paragraph style={{ textAlign: 'center', marginTop: '24px' }}>
            Today, the same entrepreneurial spirit continues to drive our culture of creativity,
            diversity, and collaboration. Trivine Infotech has helped companies across verticals
            transform their traditional business to evolve to the changing way of how business is
            done by end-to-end implementations, mergers & acquisition projects, SAP upgrades, SAP
            enterprise planning & budgeting projects, ECC to SAP S/4HANA migrations and developing
            simple and elegant solutions for their complex workflows.
          </Typography>
        </Card>
      </div>
      <div className="trivine-item5">
        <div
          style={{
            backgroundColor: 'rgb(234, 111, 102)',
            height: '200px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}>
          <CommentIcon
            style={{
              color: 'white',
              marginTop: '75px',
              marginRight: '24px',
              fontSize: '40px'
            }}
          />
          <Typography
            variant="h5"
            style={{
              color: 'white',
              alignSelf: 'center',
              fontSize: '40px',
              lineHeight: '40px'
            }}>
            Customer Satisfaction: Our Reviews Speak for Themselves
          </Typography>
        </div>
      </div>
      <div className="trivine-item-comments">
        <Card style={{ width: '345px', margin: '20px' }}>
          <CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                "...Expertise and Initutive..."
              </Typography>
              <Typography variant="body2" color="text.secondary" style={{ paddingTop: '24px' }}>
                Professionalism, expertise, understood the requirement properly and the job was done
                on time. They made the entire process comfortable and a pleasant experience. 5 STARS
                to them. I highly recommend Trivine infotech
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                style={{
                  paddingTop: '64px',
                  fontWeight: 'bold',
                  textAlign: 'right'
                }}>
                -EastVantage
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card style={{ maxWidth: 345, width: '345px', margin: '20px' }}>
          <CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                "... Very happy all round service ..."
              </Typography>
              <Typography variant="body2" color="text.secondary" style={{ paddingTop: '24px' }}>
                I would like to take an opportunity to say thanks to both of you. You have extended
                your support till the resolution, and helped us to rectify the issue. I highly
                appreciate your customer centric approach, taking the ownership of the issues, and
                ensured the resolution.
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                style={{
                  paddingTop: '24px',
                  fontWeight: 'bold',
                  textAlign: 'right'
                }}>
                -Knack Packaging
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card style={{ maxWidth: 345, width: '345px', margin: '20px' }}>
          <CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                "... On-time delivery and clear communication ..."
              </Typography>
              <Typography variant="body2" color="text.secondary" style={{ paddingTop: '48px' }}>
                The SAP service was top-notch. We got our requests fulfilled on time and as
                expected. The communication throughout the process was clear and helpful. Overall,
                we were extremely satisfied with the service and would highly recommend it.
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                style={{
                  paddingTop: '24px',
                  fontWeight: 'bold',
                  textAlign: 'right'
                }}>
                -Alkyl
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
      <div className="trivine-footer">
        <Footer />
      </div>
    </div>
  );
}

export default About;

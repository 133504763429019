import { Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import * as React from 'react';
import sap_1 from '../images/SAP/sap_1.jpg';
import sap_2 from '../images/SAP/sap_2.jpg';
import sap_3 from '../images/SAP/sap_3.jpg';
import sap_4 from '../images/SAP/sap_4.jpg';
import sap_5 from '../images/SAP/sap_5.jpg';
import '../styles/services.css';
import Footer from './Footer';
import Navbar from './Navbar';

function Services_SAP() {
  return (
    <>
      <div className="service-grid-container">
        <div className="service-header">
          <Navbar />
        </div>
        <div className="service-item1">
          <div>
            <img
              src={sap_2}
              alt=" SAP Consulting"
              style={{
                height: '350px',
                width: '100%',
                borderRadius: '5px'
              }}
            />
          </div>
          <div style={{ flex: 1, textAlign: 'center', paddingTop: '24px' }}>
            <Typography
              variant="h5"
              style={{
                background: 'linear-gradient(90deg,#f32170,#ff6b08,#cf23cf,#ed4)',
                '-webkit-text-fill-color': 'transparent',
                '-webkit-background-clip': 'text'
              }}>
              SAP Consulting
            </Typography>
            <Typography style={{ padding: '24px', textAlign: 'left' }}>
              Supporting a broad range of corporations, institutions and governments by providing
              strategic advice, application development and SAP solutions with innovative and
              next-gen touch that can truly transform businesses. World-class applications give you
              a proven foundation for unified operations and next-generation technologies propel you
              into the digital economy.
            </Typography>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', margin: '36px' }}>
          <Typography
            variant="h4"
            style={{
              width: '50%',
              wordBreak: 'break-word',
              textAlign: 'center',
              background: 'linear-gradient(90deg,#f32170,#ff6b08,#cf23cf,#ed4)',
              '-webkit-text-fill-color': 'transparent',
              '-webkit-background-clip': 'text'
            }}>
            Transformation done with ease
          </Typography>
        </div>
        <div className="service-item2">
          <Card style={{ width: '345px', margin: '20px' }}>
            <CardActionArea>
              <CardMedia component="img" height="250" image={sap_3} alt="green iguana" />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  We are certified SAP partner
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Regardless of your company size or industry – we can help you buy, build,
                  implement, service, support and run the SAP solution that best fits your unique
                  needs.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card style={{ maxWidth: 345, width: '345px', margin: '20px' }}>
            <CardActionArea>
              <CardMedia component="img" height="250" image={sap_4} alt="green iguana" />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  Transform with speed and efficiency
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  We help you fast track your digital transformation with our pre-defined industry
                  standard wireframes, best-practises and processes.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card style={{ maxWidth: 345, width: '345px', margin: '20px' }}>
            <CardActionArea>
              <CardMedia component="img" height="250" image={sap_5} alt="green iguana" />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  SAP certified consultants and experts
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  With the help of our global experts we help you leap. Our team has more than 15+
                  year relevant industry experience and understand the business at the heart.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
        <div className="service-item3">
          <div style={{ flex: 1, textAlign: 'center', paddingTop: '24px' }}>
            <Typography
              variant="h5"
              style={{
                background: 'linear-gradient(90deg,#f32170,#ff6b08,#cf23cf,#ed4)',
                '-webkit-text-fill-color': 'transparent',
                '-webkit-background-clip': 'text'
              }}>
              SAP Digital Transformation Services
            </Typography>
            <Typography style={{ padding: '24px', textAlign: 'left' }}>
              Our approach to serving clients has always centered on doing first-class business in a
              first-class way. We strive to build trusted, long-term relationships by taking a
              holistic and forward-looking view on our relationships, and identifying ways to help
              clients achieve their most important business objectives. We help you with variety of
              things: ERP and Finance, Database and Data Management, Analytics, CRM and Customer
              Experience, Human capital management, Digital supply chain and Network and spend
              management
            </Typography>
            <Typography style={{ padding: '24px', textAlign: 'left' }}>
              With Trivine Infotech bring intelligence to help your business and compete more
              effectively.Enabled by SAP technology, our customers are transforming the industry,
              improving customer experiences, and gaining significant competitive advantage. With
              this significant advantage they become leaders in their industry because they have
              proper visibility and transparency of business process with one unified solution.
            </Typography>
          </div>
          <div>
            <img
              src={sap_1}
              alt="Services SAP"
              style={{
                height: '350px',
                width: '100%',
                borderRadius: '5px'
              }}
            />
          </div>
        </div>
        <div className="service-item4">
          <div>
            <img
              src={sap_2}
              alt="Leverage the established leader in SAP software implementations."
              style={{
                height: '200px',
                width: '100%',
                borderRadius: '5px'
              }}
            />
          </div>
          <div style={{ flex: 1, textAlign: 'center', paddingTop: '24px' }}>
            <Typography variant="h5">
              Leverage the established leader in SAP software implementations.
            </Typography>
            <Typography style={{ padding: '24px', textAlign: 'left' }}>
              We are experts in enterprise systems integration and digital transformation, including
              game changing priorities for our clients such as customer experience and data and
              analytics.
            </Typography>
          </div>
        </div>
      </div>
      <div className="service-footer">
        <Footer />
      </div>
    </>
  );
}

export default Services_SAP;

import { Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import * as React from 'react';
import integration_1 from '../images/Integration/integration_1.jpg';
import integration_2 from '../images/Integration/integration_2.jpg';
import integration_3 from '../images/Integration/integration_3.jpg';
import integration_4 from '../images/Integration/integration_4.jpg';
import integration_5 from '../images/Integration/integration_5.jpg';
import '../styles/services.css';
import Footer from './Footer';
import Navbar from './Navbar';

function Services_Integration() {
  return (
    <>
      <div className="service-grid-container">
        <div className="service-header">
          <Navbar />
        </div>
        <div className="service-item1">
          <div style={{}}>
            <img
              src={integration_1}
              alt="Integration Service"
              style={{
                height: '350px',
                width: '100%',
                borderRadius: '5px'
              }}
            />
          </div>
          <div style={{ flex: 1, textAlign: 'center', paddingTop: '24px' }}>
            <Typography
              variant="h5"
              style={{
                background: 'linear-gradient(90deg,#f32170,#ff6b08,#cf23cf,#ed4)',
                '-webkit-text-fill-color': 'transparent',
                '-webkit-background-clip': 'text'
              }}>
              Integration Services
            </Typography>
            <Typography style={{ padding: '24px', textAlign: 'left' }}>
              With a comprehensive combination of applications and data, you can automate business
              processes with confidence and agility. With the everyday changing techonology, what
              you need is an experienced partner by your side, who can reduce the complexity of your
              business systems by making sure your application portfolio is up to the latest
              standards and cutting-edge technology. Trivine Infotech has many years of SAP
              integration experience and is extremely knowledgeable in a wide range of interface
              technologies.
            </Typography>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', margin: '36px' }}>
          <Typography
            variant="h4"
            style={{
              width: '50%',
              wordBreak: 'break-word',
              textAlign: 'center',
              background: 'linear-gradient(90deg,#f32170,#ff6b08,#cf23cf,#ed4)',
              '-webkit-text-fill-color': 'transparent',
              '-webkit-background-clip': 'text'
            }}>
            The holistic integration approach
          </Typography>
        </div>
        <div className="service-item2">
          <Card style={{ width: '345px', margin: '20px' }}>
            <CardActionArea>
              <CardMedia component="img" height="250" image={integration_3} alt="green iguana" />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  Increase business productivity with integration and automation
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Today’s tech enabled organization has multiple software and data sources. Get
                  benefit of all of them in one unified place.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card style={{ maxWidth: 345, width: '345px', margin: '20px' }}>
            <CardActionArea>
              <CardMedia component="img" height="250" image={integration_4} alt="green iguana" />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  Customizable
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Use any software. Our Agile integration champions are master in implementing
                  integration system ensuring customer can get value out of it.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card style={{ maxWidth: 345, width: '345px', margin: '20px' }}>
            <CardActionArea>
              <CardMedia component="img" height="250" image={integration_5} alt="green iguana" />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  Easier, faster and scalable
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  You can be anywhere in the part of your journey, our services help you scale,
                  maintain and grow with the right set of tools and processes.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
        <div className="service-item3">
          <div style={{ flex: 1, textAlign: 'center', paddingTop: '24px' }}>
            <Typography
              variant="h5"
              style={{
                background: 'linear-gradient(90deg,#f32170,#ff6b08,#cf23cf,#ed4)',
                '-webkit-text-fill-color': 'transparent',
                '-webkit-background-clip': 'text'
              }}>
              The need for Integration
            </Typography>
            <Typography style={{ padding: '24px', textAlign: 'left' }}>
              We help you manage everything at once place with our cutting-edge tools designed
              specifically for particular industry helping them scale sustainably. With the shift of
              on-premise technology to cloud it has started becoming more and more complicated to
              integrate system, with the help of our cloud experts we help you integrate on premise
              system with cloud systems seamlessly.
            </Typography>
            <Typography style={{ padding: '24px', textAlign: 'left' }}>
              Achieve ultimate business value from integrating heterogeneous SAP and external
              systems and processes, enabling more effective data management while saving costs and
              resources on support.
            </Typography>
          </div>
          <div style={{}}>
            <img
              src={integration_2}
              alt="Integration Services"
              style={{
                height: '350px',
                width: '100%',
                borderRadius: '5px'
              }}
            />
          </div>
        </div>
        <div className="service-item4">
          <div>
            <img
              src={integration_1}
              alt="Quickly adapt to changing business conditions and customer demands"
              style={{
                height: '200px',
                width: '100%',
                borderRadius: '5px'
              }}
            />
          </div>
          <div style={{ flex: 1, textAlign: 'center', paddingTop: '24px' }}>
            <Typography variant="h5">
              Quickly adapt to changing business conditions and customer demands
            </Typography>
            <Typography style={{ padding: '24px', textAlign: 'left' }}>
              Connect and enhance your applications, automate business processes, and deliver
              personalized experiences using low-code and no-code development tools.
            </Typography>
          </div>
        </div>
      </div>
      <div className="service-footer">
        <Footer />
      </div>
    </>
  );
}

export default Services_Integration;
